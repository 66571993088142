<template>
  <v-container fluid py-1 pr-1 pl-0 fill-height>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" sm="12" md="12" lg="12" class="fill-height">
        <v-card class="elevation-1 fill-height">
          <v-card-title class="text-center justify-left py-6">
            <span>Inv# {{invoiceInfo.hasOwnProperty('invoice') ? invoiceInfo.invoice.invoice_no : ""}}</span>
            <v-spacer></v-spacer>
            <v-btn icon color="purple" @click="closeInvoiceView">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height:88%">
            <component :is="componentLoader" :invoiceId="$router.currentRoute.params.invoiceId"></component>
            <!-- <invoice-template1 :invoiceId="$router.currentRoute.params.invoiceId"></invoice-template1>  -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
// import html2canvas from "html2canvas";
// import { API } from "../../api.js";
export default {
  name: "invoice-view",
  components: {
  },
  data() {
    return {
      invoice: {},
      invoiceTemplate:""
    };
  },
  watch: {
    $route(to, from) {
      var self = this;
      if (to.name == "viewinvoice") {
        self.$store
          .dispatch({
            type: "MDMStore/getInvoiceInfo",
            invoiceId: self.$router.currentRoute.params.invoiceId
          })
          .then(data => {
            self.invoice = Object.assign({}, data);
          });
      }
    },
    invoiceNo(invoiceNo) {
    }
  },
  computed: {
    componentLoader() {
      var defaultTemplate = this.$store.getters['MDMStore/getterOrgDefaultInvoiceTemplate'];
      this.invoiceTemplate = defaultTemplate.template_name;
      switch(this.invoiceTemplate){
        case 'standard':
          return () => import(`../invoices/invoiceTemplates/Standard`);
        case 'compact':
          return () => import(`../invoices/invoiceTemplates/Compact`);
        case 'simple':
          return () => import(`../invoices/invoiceTemplates/Simple`);
       default:
         return () => import(`../invoices/invoiceTemplates/Standard`);
      }
    },
    invoiceInfo() {
      return this.$store.getters["MDMStore/getterSelectedInvoiceInfo"];
    }
  },
  beforeCreate() {},
  mounted() {
    var self = this;
    self.$store
      .dispatch({
        type: "MDMStore/getInvoiceInfo",
        invoiceId: self.$router.currentRoute.params.invoiceId
      })
      .then(data => {
        self.invoice = Object.assign({}, data);
      });
      this.$store.dispatch({
      type: "MDMStore/getOrgCustomFields",
      orgId: localStorage.getItem("orgId")
    });
  },
  methods: {
    closeInvoiceView() {
      this.$router.replace({ path: "/invoices" });
    },
    editInvoice() {}
  }
};
</script>
