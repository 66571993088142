<template>
  <v-container fluid pa-1>
    <v-row no-gutters>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card flat tile>
          <v-card-title class="secondary">
            <span>Invoices</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchInvoice"
              append-icon="search"
              label="Search"
              solo
              flat              
              hide-details
            ></v-text-field>
             <v-menu bottom >
              <template v-slot:activator="{ on }">
                <v-btn color="blue" icon v-on="on">
                  <v-icon>settings_applications</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item to="/settings/manage-custom-fields">
                  <v-list-item-title>Manage Custom Fields</v-list-item-title>
                </v-list-item>
                <v-list-item to="/settings/manage-tariffs">
                  <v-list-item-title>Manage Tariffs</v-list-item-title>
                </v-list-item>
                <v-list-item to="/settings/manage-invoice-templates">
                  <v-list-item-title>Manage Invoice Templates</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="purple" v-on="on" dark @click="createNewInvoice">
                  <v-icon dark>add</v-icon>
                </v-btn>
              </template>
              <span>Create new invoice</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-if="invoices.length != 0"
              :headers="headers"
              :items="invoices"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :search="searchInvoice"
              class="elevation-0"
              hide-default-footer
              @page-count="pageCount = $event"
              :hide-default-header="isCompact"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="!isCompact">
                 <tr v-for="item in items" :key="item.invoice_no">
                    <td class="text-center">
                      <a class="blue--text" @click="onClickInvoice(item)">{{ item.invoice_no }}</a>
                    </td>
                    <td
                      class="text-center"
                    >{{ item.hasOwnProperty('created_at') ? (item.created_at).split("T")[0] : "" }}</td>
                    <td
                      class="text-center"
                    >{{ item.customer.first_name + " " +item.customer.last_name }}</td>
                    <td
                      class="text-center"
                    >{{ item.hasOwnProperty('period_from') ? (item.period_from).split("T")[0] : "" }}</td>
                    <td
                      class="text-center"
                    >{{ item.hasOwnProperty('period_to') ? (item.period_to).split("T")[0] : "" }}</td>
                    <td class="text-center">{{ (Number(item.amount)).toFixed(2) }}</td>
                    <!-- <td class="justify-center layout px-0">
                    <v-btn icon class="mx-0">
                      <v-icon color="green darken-3">vertical_align_bottom</v-icon>
                    </v-btn>
                    </td>-->
                  </tr>
                </tbody>
               <tbody v-else>
                  <tr v-for="item in items" :key="item.invoice_no">
                    <td class="text-center">
                      <a class="blue--text" @click="onClickInvoice(item)">{{ item.invoice_no }}</a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <div v-else>
              <p>No Invoices found.</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-pagination v-if="invoices.length >= 10 " class="mt-3" light v-model="page" :length="pageCount"></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "invoices-list",
  components: {},
  props: {
    isCompact: { type: Boolean }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      searchInvoice: "",
      items: [],
       headers: [
        { text: "Invoice #", value: "invoice_no", align: "center" },
        {
          text: "Date Created",
          align: "center",
          sortable: true,
          value: "created_at"
        },
        { text: "Customer Name", value: "first_name", align: "center" },
        { text: "Period From", value: "period_from", align: "center" },
        { text: "Period To", value: "period_to", align: "center" },
        // { text: "Tariff", value: "state", align: "center" },
        { text: "Amount", value: "amount", align: "center" }
        //  { text: "Actions", value: "", sortable: false, align: "center" }
      ]
    };
  },
  computed: {
    invoices() {
      return this.$store.getters["MDMStore/getterInvoices"];
    }
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    onClickInvoice(invoice) {
      this.$router.replace({ path: "/invoices/invoice/" + invoice.id });
    },
   createNewInvoice() {
      this.$router.push({ path: "/invoices/new" });
    },
  }
};
</script>
