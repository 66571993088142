<template>
  <v-container fluid pa-0 fill-height>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" :sm="invoiceListFlex" :md="invoiceListFlex" :lg="invoiceListFlex">
        <invoices-list :isCompact="invoiceListFlex == 3"></invoices-list>
      </v-col>
      <v-col col="auto" md="9" sm="9" lg="9" v-if="invoiceListFlex == 3" class="fill-height">
        <invoice-view></invoice-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InvoicesList from "../components/invoices/InvoicesList";
import InvoiceView from "../components/invoices/InvoiceView";

export default {
  components: {
    InvoicesList,
    InvoiceView
  },
  data() {
    return {
      invoiceListFlex: 12
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.$store.dispatch({
      type: "MDMStore/getInvoices"
    });
      if (vm.$router.currentRoute.name == "viewinvoice") {
        vm.invoiceListFlex = 3;
      } else {
        vm.invoiceListFlex = 12;
      }
    });
  },
  watch: {
    $route(to, from) {
      if (to.name == "viewinvoice") {
        this.invoiceListFlex = 3;
      } else {
        this.invoiceListFlex = 12;
      }
    }
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch({
        type: "MDMStore/getOrgDetails",
        orgId: localStorage.getItem("orgId")
      });
    this.$store.dispatch({
      type: "MDMStore/getInvoices"
    });
  }
};
</script>
